<template>
  <main class="py-12 px-4">
    <NotFound />
  </main>
</template>

<script>
import NotFound from "../components/NotFound";

export default {
  name: "error",
  metaInfo: {
    title: "Page not found",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "This page was not found"
      }
    ]
  },
  components: {
    NotFound
  }
};
</script>
